<template>
  <div>
    <p class="mb-2">{{ stopsCount === 0 ? 'Origin' : 'Stop' }} Location</p>
    <GmapAutocomplete
      class="google-ac round-edges"
      id="google-ac"
      @place_changed='setPlace'
    />

    <v-text-field class="mt-8" v-model="stop.location_label" label="Location Label *" outlined></v-text-field>

    <v-row>
      <v-col cols="12" md="9" class="pt-0 font-date-range">
        <dateRange
          ref="picker"
          opens="center"
          :minDate="new Date()"
          :singleDatePicker="stopsCount <= 0 || home || stop.stop_number === stopsCount"
          timePicker
          timePicker24Hour
          :showWeekNumbers="false"
          showDropdowns
          :ranges="!home  || stop.stop_number === stopsCount"
          autoApply
          v-model="dateRange"
          appendToBody
          closeOnEsc
          class="full-width round-edges my-3 font-date-range"
          :locale-data="{ firstDay: 1, format: 'dd-mmm-yyyy' }"
        >
          <template v-slot:input="picker" class="full-width">
            <v-row align="center" class="pt-2" v-if="picker.startDate">
              <v-col v-if="!home" class="right-border caption">
                <v-icon>mdi-calendar-month</v-icon>
                {{ picker.startDate.toDateString() }} {{ picker.startDate.toTimeString().substring(0, 5) }}
              </v-col>
              <v-col v-if="stopsCount > 0 && stop.stop_number !== stopsCount" class="caption">
                <v-icon>mdi-calendar-month</v-icon>
                {{ picker.endDate.toDateString() }} {{ picker.endDate.toTimeString().substring(0, 5) }}
              </v-col>
            </v-row>
          </template>
        </dateRange>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          :items="sequence"
          v-model="stop.stop_number"
          outlined
          label="Stop number *"
        >
        </v-select>
      </v-col>
    </v-row>
    <!-- stop transportations -->
    <div v-if="stopsCount > 1 || stop.stop_number > 1">
      <p class="primary--text mb-2 mt-5">Travel by:</p>
      <v-tooltip color="primary" v-for="item in transportations" :key="item.id" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :icon="stop.stop_travel_by_id !== item.id"
            :fab="stop.stop_travel_by_id === item.id"
            color="primary"
            elevation="0"
            class="mx-5"
            @click="stop.stop_travel_by_id = item.id"
          >
            <v-icon v-if="item.slug !== 'Office Building'" v-text="item.slug"></v-icon>
            <v-icon v-else v-text="Hotel"></v-icon>
          </v-btn>
        </template>
        <span class="text-capitalize">{{ item.title }}</span>
      </v-tooltip>
    </div>

    <!-- stop stays -->
    <div v-if="(stopsCount > 1 || stop.stop_number > 1 )&& !home">
      <p class="primary--text mb-2 mt-5">Stay in:</p>
      <v-tooltip color="primary" v-for="item in stays" :key="item.id" bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            v-on="on"
            :icon="stop.stop_stay_in_id !== item.id"
            :fab="stop.stop_stay_in_id === item.id"
            color="primary"
            elevation="0"
            class="mx-5"
            @click="stop.stop_stay_in_id = item.id"
          >
            <v-icon v-text="item.slug"></v-icon>
          </v-btn>
        </template>
        <span class="text-capitalize">{{ item.title }}</span>
      </v-tooltip>
    </div>

    <v-row class="mt-5" no-gutters>
      <v-spacer></v-spacer>
      <v-btn @click="$emit('closeForm', 'cancelled')" text>Cancel</v-btn>
      <v-btn @click="type === 'edit' ? updateStop() : saveStop()"
             :loading="addStopLoad"
             :disabled="(!stop.location_label || !currentPlace) && !stop.stop_To_lat"
             class="ml-5" color="primary white--text" elevation="0"
      >
        {{ type === 'edit' ? 'update' : 'save' }}
      </v-btn>
    </v-row>
  </div>
</template>

<script>
import planner from '@/web_services/planner'

export default {
  props: ['stopsCount', 'tripId', 'stopIndex', 'transportations', 'stays', 'type', 'editingStop', 'stops', 'home'],
  data () {
    return {
      stop: {
        trip_plan_id: 1,
        stop_number: null,
        stop_name: '',
        formatted_address: '',
        location_label: '',
        stop_from_date: null,
        stop_to_date: null,
        stop_To_lat: 0,
        stop_To_lng: 0,
        stop_travel_by_id: null,
        stop_stay_in_id: null,
        home: false
      },
      currentPlace: null,
      locationLabel: '',
      dateRange: {
        startDate: new Date(),
        endDate: new Date()
      },
      stopNumber: 1,
      travelBy: 'car',
      addStopLoad: false,
      showTimeRangePanel: false
    }
  },
  computed: {
    sequence () {
      if (!this.stopsCount || this.stop.stop_number === 1) {
        this.setStopNum(1)
        return [1]
      } else if (this.stopsCount === 1) {
        this.setStopNum(2)
        return [2]
      } else {
        var list = []
        let limit = this.stopsCount
        if (this.type !== 'edit') limit = this.stopsCount + 1
        for (var i = 2; i <= limit; i++) {
          list.push(i)
        }
        if (this.type !== 'edit') this.setStopNum(list[list.length - 1])
        return list
      }
    }
  },
  methods: {
    setPlace (place) {
      this.currentPlace = place
    },
    setStopNum (num) {
      this.stop.stop_number = num
    },
    setStopProps () {
      this.addStopLoad = true
      if (this.home) {
        this.stop.home = 1
      }
      if (this.currentPlace) {
        this.stop.stop_To_lat = this.currentPlace.geometry.location.lat()
        this.stop.stop_To_lng = this.currentPlace.geometry.location.lng()
        this.stop.stop_name = this.currentPlace.name
        this.stop.formatted_address = this.currentPlace.formatted_address
      }
      if (this.dateRange.startDate && this.dateRange.endDate) {
        this.stop.stop_from_date = this.$options.filters.date(this.dateRange.startDate)
        this.stop.stop_to_date = this.$options.filters.date(this.dateRange.endDate)
      }
      this.stop.trip_plan_id = this.tripId
    },
    updateStop () {
      this.setStopProps()
      const id = this.stop.id
      delete this.stop.id
      planner.updatePlanStop(this.stop, id).then(response => {
        this.$emit('setStops', response.data.data)
        this.dateRange.startDate = new Date(response.data.data[response.data.data.length - 1].stop_to_date)
        this.dateRange.endDate = new Date(response.data.data[response.data.data.length - 1].stop_to_date)
        this.resetStopObject()
      }).catch(err => {
        this.$emit('error', 'error', err.response.data.errors)
      }).finally(() => {
        this.addStopLoad = false
        if (this.home) {
          this.$emit('closeForm', 'home')
        } else {
          this.$emit('closeForm', 'done')
        }
      })
    },
    saveStop () {
      // delete this.stop.stop_number
      this.setStopProps()
      planner.addPlanStop(this.stop).then(response => {
        this.$emit('setStops', response.data.data)
        this.dateRange.startDate = new Date(this.stop.stop_to_date)
        this.dateRange.endDate = new Date(this.stop.stop_to_date)
        this.resetStopObject()
      }).catch(err => {
        this.$emit('error', 'error', err.response.data.errors)
      }).finally(() => {
        this.addStopLoad = false
        if (this.home) {
          this.$emit('closeForm', 'home')
        } else {
          this.$emit('closeForm', 'done')
        }
      })
    },
    resetStopObject () {
      this.currentPlace = null
      document.getElementsByClassName('google-ac')[0].value = ''
      this.stop = {
        trip_plan_id: 1,
        stop_number: null,
        stop_name: '',
        formatted_address: '',
        location_label: '',
        stop_from_date: null,
        stop_to_date: null,
        stop_To_lat: 0,
        stop_To_lng: 0,
        stop_travel_by_id: null,
        stop_stay_in_id: null
      }
    }
  },
  mounted () {
    if (this.type === 'edit') {
      if (this.$route.name === 'plan-view') {
        document.getElementsByClassName('google-ac')[0].value = this.editingStop.formatted_address
      } else {
        document.getElementById('google-ac').value = this.editingStop.formatted_address
      }
    }
    if (this.home && this.$route.name !== 'plan-view') {
      console.log('here')
      this.stop = this.stops[0]
      this.stop.location_label = 'Go Home'
      this.locationLabel = 'Go Home'
      this.stop.stop_number = this.stops.length
      document.getElementById('google-ac').value = this.stops[0].formatted_address
    }
  },
  created () {
    if (this.type === 'edit') {
      this.stop = JSON.parse(JSON.stringify(this.editingStop))
      this.dateRange.startDate = new Date(this.stop.stop_from_date)
      this.dateRange.endDate = new Date(this.stop.stop_to_date)
    } else {
      if (this.stopsCount > 0) {
        this.dateRange.startDate = new Date(this.stops[this.stopsCount - 1].stop_to_date)
        this.dateRange.endDate = new Date(this.stops[this.stopsCount - 1].stop_to_date)
      }
    }
  }
}
</script>
<style>
.daterangepicker .calendar-table td, .daterangepicker .calendar-table th {
  font-family: Arial, Helvetica, sans-serif
}
</style>
