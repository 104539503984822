<template>
    <div class="plan-view">
        <v-container fluid class="px-10 my-7">
          <v-snackbar class="white--text text-center" top v-model="snackbar" :timeout="3000" :color="notificationType">{{notificationText}}</v-snackbar>
          <v-row justify="center" v-if="loading && !plan" class="mb-15">
            <v-progress-circular
            :size="70"
            :width="7"
            color="primary"
            indeterminate
            ></v-progress-circular>
          </v-row>
          <v-btn text color="primary" large class="mb-5" @click="$router.push({name: 'plansList'})" v-if="$route.name !== 'plan-announce'"><v-icon left>mdi-keyboard-return</v-icon> Return TO plans</v-btn>
          <v-btn v-if="!$cookies.isKey('gt_user_token')" elevation="0" color="primary" large class="mb-5" @click="$router.push({name: 'login'})"><v-icon left>mdi-account-check</v-icon>Join us Now</v-btn>
          <v-row class="mb-10" v-if="!loading && plan">
           <v-col cols="12" md="5">
              <gmap-map
              ref="gmap"
              id="gmap-cont"
              :center='center'
              :zoom='12'
              style='width:100%;  height: 400px;'
              >
                <gmap-marker
                :key="index"
                v-for="(m, index) in markers"
                :position="m.position"
                @click="center = m.position"
                />
                <gmap-polyline v-bind:path.sync="path" v-bind:options="{ strokeColor:'#FF8F95'}" />
              </gmap-map>
            </v-col>
            <v-col cols="12" md="7">
                <div class="d-flex justify-space-between mb-3">
                    <h2 class="text-bold heading-md">{{plan.name}}</h2>
                    <div>
                      <v-btn icon color="primary" large class="mx-2" v-if="$route.name !== 'plan-announce'"><v-icon>mdi-file-move</v-icon></v-btn>
                      <v-btn icon color="primary" large class="mx-2" v-if="$route.name !== 'plan-announce'"><v-icon>mdi-heart-outline</v-icon></v-btn>
                      <v-btn icon color="primary" large class="mx-2" @click="socialDialog = true"><v-icon>mdi-share-variant</v-icon></v-btn>
                    </div>
                </div>
                <p class="paragraph--text">{{plan.description}}</p>

                <!-- stops -->
                <h3 class="primary--text text-bold my-3">Destination Stops</h3>
                <v-card v-if="plan.trip_stops.length" outlined class="pa-5">
                  <div v-for="(item, i) in plan.trip_stops" :key="item.id">
                    <v-row no-gutters justify="space-between" align="center">
                      <v-col cols="12" :md="$route.name !== 'plan-announce' ? 4 : 6">
                        <div class="text-capitalize body-1 text-bold">
                          {{item.stop_number + ' '}}
                          <v-icon v-if="item.stop_travel_by_id && transportations.length" class="mx-2">
                            {{transportations.find(trans => trans.id === item.stop_travel_by_id).slug}}
                          </v-icon>
                          {{item.location_label}}
                          <br/>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on" class="caption text-truncate d-block mx-2">{{item.formatted_address}}</span>
                            </template>
                            <span>{{item.formatted_address}}</span>
                          </v-tooltip>
                        </div>
                      </v-col>
                      <v-col cols="12" md="4" class="text-center caption my-2">
                        <v-icon left>mdi-calendar</v-icon> {{new Date(item.stop_from_date).toDateString()}} {{new Date(item.stop_from_date).toTimeString().substring(0, 5)}}
                      </v-col>
                      <v-col v-if="item.stop_number > 1 && item.stop_number != plan.trip_stops.length" cols="12" md="4" class="text-center caption my-2 text-center">
                        <v-icon left>mdi-calendar</v-icon> {{new Date(item.stop_to_date).toDateString()}} {{new Date(item.stop_to_date).toTimeString().substring(0, 5)}}
                      </v-col>
                      <!-- <v-col cols="12" md="2" v-if="$route.name !== 'plan-announce'">
                        <v-btn text small color="primary" @click="stop = item; stopIndex = i; actionType = 'edit'; stopsFromDialog = true;">
                          <v-icon>mdi-square-edit-outline</v-icon>
                          Edit
                        </v-btn>
                        <v-btn text small color="primary" v-if="i !== 0" @click="deleteStop(item.id)">
                            <v-icon>mdi-trash-can-outline</v-icon>
                            Delete
                        </v-btn>
                      </v-col> -->
                  </v-row>
                  <v-divider class="my-3" v-if="i + 1 < plan.trip_stops.length"></v-divider>
                  </div>
                </v-card>
                <!-- <v-btn v-if="$route.name !== 'plan-announce'" x-large text color="primary" @click="actionType = 'add'; stopsFromDialog = true;">
                  <v-icon left>mdi-plus-circle</v-icon>
                  add stop
                </v-btn> -->
            </v-col>
          </v-row>
          <h3 v-if="!loading && !plan">{{text}}</h3>

          <!-- add or update stop form -->
          <v-dialog v-model="stopsFromDialog" max-width="90%" min-width="70%">
            <v-card class="pa-5" v-if="plan">
              <stopForm
              v-if="stopsFromDialog"
              :type="actionType"
              :stopsCount="plan.trip_stops.length"
              :stops="plan.trip_stops"
              :stopIndex="stopIndex"
              :home="stopIndex === 0"
              :editingStop="stop"
              :tripId="id"
              :transportations="transportations"
              :stays="stays"
              @closeForm="stopsFromDialog = false"
              @setStops="setStops"
              @error="showError"
              />
            </v-card>
          </v-dialog>
        </v-container>
        <v-dialog v-model="socialDialog" v-if="plan">
          <v-card>
            <v-card-title>Share your trip link</v-card-title>
            <v-card-text class="pa-10">
              <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{backgroundColor: network.color, margin: '50px', padding: '10px', color: 'white'}"
              :url="currentUrl + '/plan-trip/plan-announce/' + plan.id"
              :title="plan.name"
              :description="plan.description"
              :quote="getQuote()"
              >
                <v-icon class="mb-2" color="white" left>{{network.icon}}</v-icon>
                <span>{{ network.name }}</span>
            </ShareNetwork>
          </v-card-text>
          </v-card>
        </v-dialog>
    </div>
</template>

<script>
import planner from '@/web_services/planner.js'
import stopForm from '@/components/plan_trip/stopForm'
import { gmapApi } from 'vue2-google-maps'

export default {
  props: ['id'],
  components: { stopForm },
  data () {
    return {
      plan: null,
      loading: true,
      text: '',
      currentPlace: null,
      places: [],
      markers: [],
      path: [],
      center: { lat: 45.508, lng: -73.587 },
      stop: null,
      stopIndex: 0,
      transportations: [],
      stays: [],
      stopsFromDialog: false,
      actionType: 'add',
      snackbar: false,
      notificationType: '',
      notificationText: '',
      socialDialog: false,
      currentUrl: '',
      networks: [
        { network: 'facebook', name: 'Facebook', icon: 'mdi-facebook', color: '#1877f2' },
        { network: 'twitter', name: 'Twitter', icon: 'mdi-twitter', color: '#1da1f2' },
        { network: 'whatsapp', name: 'Whatsapp', icon: 'mdi-whatsapp', color: '#25d366' }
      ]
    }
  },
  computed: {
    google: gmapApi
  },
  methods: {
    setStops (stops) {
      this.plan.trip_stops = stops
      this.setMap()
      this.stopsFromDialog = false
    },
    deleteStop (id) {
      planner.deletePlanStop({ trip_plan_id: this.id }, id).then(response => {
        this.plan.trip_stops.splice(this.plan.trip_stops.findIndex(a => a.id === id), 1)
        this.setMap()
      }).catch(err => {
        this.showNotification('error', err.response.data.message)
      })
    },
    setMap () {
      this.markers = []
      this.path = []
      this.plan.trip_stops.forEach(stop => {
        const marker = {
          lat: stop.stop_To_lat,
          lng: stop.stop_To_lng
        }
        this.markers.push({ position: marker })
        this.path.push(marker)
        this.center = marker
      })
      setTimeout(() => {
        this.$refs.gmap.$mapPromise.then((map) => {
          const bounds = new this.google.maps.LatLngBounds()
          for (const m of this.markers) {
            bounds.extend(m.position)
          }
          map.fitBounds(bounds)
        })
      }, 500)
    },
    getQuote () {
      let article = this.plan.name + '\n' + this.plan.description
      this.plan.trip_stops.forEach((stop, i) => {
        if (i === 0) {
          article += '\n Step ' + (i + 1) + ': We\'ll start from ' + stop.formatted_address + ' at : ' + stop.stop_from_date
        } else if (i === this.plan.trip_stops.length - 1) {
          article += '\n Step ' + (i + 1) + ': We\'ll go home'
        } else {
          article += '\n Step ' + (i + 1) + ': We\'ll go to ' + stop.formatted_address + ' From : ' + stop.stop_from_date + ', To : ' + stop.stop_to_date
        }
      })
      return article
    },
    showError (type, msg) {
      let message = ''
      Object.entries(msg).forEach(error => {
        message += error[1]
      })
      this.showNotification(type, message)
    },
    showNotification (color, text) {
      this.snackbar = true
      this.notificationType = color
      this.notificationText = text
    }
  },
  mounted () {
  },
  created () {
    this.currentUrl = window.location.origin
    if (this.$route.name === 'plan-announce') {
      planner.planAnnonce(this.id).then(response => {
        this.plan = response.data.data
        this.setMap()
      }).catch(err => {
        this.text = err.response.data.message
      }).finally(() => {
        this.loading = false
      })
    } else {
      planner.showPlan(this.id).then(response => {
        this.plan = response.data.data
        this.setMap()
      }).catch(err => {
        this.text = err.response.data.message
      }).finally(() => {
        this.loading = false
      })
    }
    planner.listTransportations().then(response => {
      this.transportations = response.data.data
    }).catch(() => {})
    planner.listStays().then(response => {
      this.stays = response.data.data
    }).catch(() => {})
  }
}
</script>
